import Seo from '@/components/Seo';
import React, { ReactNode, Suspense } from 'react';
import '@/static/styles/normalize.css';
import '@/static/styles/layout.css';

// Types
type LayoutProps = {
  children: ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  return (
    <React.Fragment>
      <Suspense>
        <Seo />
        {children}
      </Suspense>
    </React.Fragment>
  );
}
